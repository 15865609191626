.input {
  position: fixed;
  z-index: -1;
  opacity: 0;
  height: 0;
}

.popup {
  :global(.ant-picker-panel-container) {
    margin-left: 0px;
    display: flex;

    :global(.ant-picker-footer) {
      min-width: initial;
      border-left: 1px solid var(--Neutral-400, #A3A3A3);
    }

    :global(.ant-picker-ranges) {
      :global(.ant-tag.ant-tag-blue) {
        background: transparent;
        border-color: transparent;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: var(--Fill-Frame-Prussian-blue, #1B2A3D);
      }
    }

    :global(.ant-picker-cell-in-range::before) {
      background: #ECF3F6;
    }

    :global(.ant-picker-cell-inner) {
      border-radius: 50% !important;
    }
  }
}
