.tooltip {
  &:global(.ant-tooltip) {
    max-width: 208px;
    padding: 0;
  }
}

.modal {
  background-color: #fff;
  border-radius: 20px;
  margin: 42px 0;

  & > div + div {
    height: 100%;
    border-radius: 20px;
  }

  &:global(.ant-modal-content) {
    height: 100%;
  }
}
